



































import Form from '@/components/organisms/Purchase/Contract/Change/CreateForm.vue'
import menuStore from '@/store/Menu'
import organizationGroupStore from '@/store/OrganizationGroups'
import organizationStore from '@/store/Organizations'
import planStore from '@/store/Plans'
import contractStore, { Contract, ContractType } from '@/store/Contracts'
import { Component, Vue } from 'vue-property-decorator'
import VABox from 'va/widgets/VABox.vue'
import dayjs from '@/libs/dayjs'
import StepBar from '@/components/organisms/Purchase/Contract/Change/StepBar.vue'
import { TAX_RATE } from '@/store/Purchase'

@Component({ components: { Form, VABox, StepBar } })
export default class extends Vue {
  contractChangeInfo: Record<string, string> = {}
  taxRate = TAX_RATE
  async mounted(): Promise<void> {
    menuStore.setActiveFlg({ settings: true })
    try {
      const contractChangeInfo = sessionStorage.getItem('ContractChangeInfo')
      if (!contractChangeInfo) throw new Error()
      this.contractChangeInfo = JSON.parse(contractChangeInfo)

      await Promise.all([
        organizationStore.fetchCurrentOrganization(),
        contractStore.fetchLatestContract(),
        contractStore.fetchFutureContract(),
        organizationGroupStore.fetchCount(),
        planStore.fetchPlans(),
      ])
      if (!this.isPossibleChangeContract(contractStore.latestContract, contractStore.futureContract)) {
        throw new Error()
      }
    } catch {
      this.$router.replace({ name: 'Contract' })
    }
  }

  isCredit(contract: Contract | null): boolean {
    return contract?.type === ContractType.CREDIT
  }

  isNegotiation(contract: Contract | null): boolean {
    return contract?.type === ContractType.NEGOTIATION
  }

  isCanceled(contract: Contract | null): boolean {
    return !!contract?.endDate
  }

  isUntilThisMonth(contract: Contract | null): boolean {
    return !!contract?.endDate && dayjs(contract.endDate).format('MM') === dayjs().format('MM')
  }

  /**
   * 契約変更ができる条件
   *
   * | 今月           | 翌月 | 終了日         | 結果  |
   * | -------------- | ---- | -------------- | ----- |
   * | クレジット     | なし | キャンセル済み | TRUE  |
   * | 相対           | なし | 今月終了       | TRUE  |
   * | それ以外の条件 |      |                | FALSE |
   */
  isPossibleChangeContract(latestContract: Contract | null, futureContract: Contract | null): boolean {
    return (
      (this.isCredit(latestContract) && !futureContract && this.isCanceled(latestContract)) ||
      (this.isNegotiation(latestContract) && !futureContract && this.isUntilThisMonth(latestContract))
    )
  }

  get latestContract(): Contract | null {
    return contractStore.latestContract
  }

  get changeMaxGroups(): number {
    return Number(this.contractChangeInfo.maxGroups)
  }

  get changeTaxIncludedPrice(): number {
    return Number(this.contractChangeInfo.amount)
  }

  get changeStartDate(): string {
    return dayjs().add(1, 'month').startOf('month').format('YYYY/MM/DD')
  }

  dateFormat(dateStr?: string): string {
    return dateStr ? dayjs(dateStr).format('YYYY/MM/DD') : ''
  }

  get startDate(): string {
    return this.dateFormat(this.latestContract?.startDate)
  }

  get endDate(): string {
    return this.dateFormat(this.latestContract?.endDate)
  }

  get maxGroups(): number | null {
    return this.latestContract?.plan?.maxGroups ?? null
  }

  get tax(): number {
    return this.taxExcludedPrice * this.taxRate
  }

  get taxIncludedPrice(): number {
    return this.taxExcludedPrice + this.tax
  }

  get taxExcludedPrice(): number {
    return this.latestContract?.plan?.price ?? 0
  }

  get isTrial(): boolean {
    return this.taxExcludedPrice === 0
  }
}
