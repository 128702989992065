




























import Spinner from '@/components/atoms/Spinner.vue'
import config from '@/config'
import contractStore, { Contract } from '@/store/Contracts'
import organizationGroupStore from '@/store/OrganizationGroups'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: { Spinner },
})
export default class extends Vue {
  contractChangeInfo: Record<string, string> = {}
  form: HTMLFormElement | null = null
  hashSource = ''
  isLoading = false
  error = ''

  sbp = {
    merchantId: config.purchase.sbp.merchantId,
    serviceId: config.purchase.sbp.serviceId,
    payType: '1', //継続課金（簡易）
    autoChargeType: '1', //自動課金する
    serviceType: '0', //売上（購入）
    divSettele: '0', //前払い
    terminalType: '0', //PC
    successUrl: config.purchase.sbp.create.successUrl,
    cancelUrl: config.purchase.sbp.create.cancelUrl,
    errorUrl: config.purchase.sbp.create.errorUrl,
    pageconUrl: config.purchase.sbp.create.pageconUrl,
  }

  mounted(): void {
    try {
      const contractChangeInfo = sessionStorage.getItem('ContractChangeInfo')
      if (!contractChangeInfo) throw new Error()
      this.contractChangeInfo = JSON.parse(contractChangeInfo)
      this.form = document.forms.namedItem('purchaseForm')
    } catch {
      this.$router.replace({ name: 'Contract' })
    }
  }

  get latestContract(): Contract | null {
    return contractStore.latestContract
  }

  get futureContract(): Contract | null {
    return contractStore.futureContract
  }

  get orgGroupCount(): number {
    return organizationGroupStore.count
  }

  get maxGroups(): number | null {
    const latestMaxGroups = this.latestContract?.plan?.maxGroups
    if (latestMaxGroups === undefined) {
      return 0
    }

    const futureMaxGroups = this.futureContract?.plan?.maxGroups
    if (futureMaxGroups === undefined) {
      return latestMaxGroups
    }

    if (latestMaxGroups === null || futureMaxGroups === null) {
      return null
    } else if (latestMaxGroups >= futureMaxGroups) {
      return latestMaxGroups
    } else {
      return futureMaxGroups
    }
  }

  async createRequest(): Promise<void> {
    this.isLoading = true
    try {
      if (!this.isValidMaxGroups()) {
        this.error = '上限グループ数が現在のグループ数未満です。'
        this.isLoading = false
        return
      }

      if (this.form === null) {
        throw 'formが設定されていません。'
      }

      this.form.pay_method.value = this.contractChangeInfo.pay_method
      this.form.merchant_id.value = this.contractChangeInfo.merchant_id
      this.form.service_id.value = this.contractChangeInfo.service_id
      this.form.cust_code.value = this.contractChangeInfo.cust_code
      this.form.order_id.value = this.contractChangeInfo.order_id
      this.form.item_id.value = this.contractChangeInfo.item_id
      this.form.item_name.value = this.contractChangeInfo.item_name
      this.form.tax.value = this.contractChangeInfo.tax
      this.form.amount.value = this.contractChangeInfo.amount
      this.form.pay_type.value = this.contractChangeInfo.pay_type
      this.form.auto_charge_type.value = this.contractChangeInfo.auto_charge_type
      this.form.service_type.value = this.contractChangeInfo.service_type
      this.form.div_settele.value = this.contractChangeInfo.div_settele
      this.form.camp_type.value = this.contractChangeInfo.camp_type
      this.form.terminal_type.value = this.contractChangeInfo.terminal_type
      this.form.success_url.value = this.contractChangeInfo.success_url
      this.form.cancel_url.value = this.contractChangeInfo.cancel_url
      this.form.error_url.value = this.contractChangeInfo.error_url
      this.form.pagecon_url.value = this.contractChangeInfo.pagecon_url
      this.form.request_date.value = this.contractChangeInfo.request_date
      this.form.sps_hashcode.value = await this.getHash()
      this.form.action = config.purchase.sbp.requestUrl
      this.form.submit()
    } catch (e) {
      this.isLoading = false
      this.error = '購入処理に失敗しました。'
      console.error(e)
    }
  }

  private getHashSource(): string {
    if (this.form === null) throw 'formが設定されていません。'

    return [
      this.form.pay_method.value,
      this.form.merchant_id.value,
      this.form.service_id.value,
      this.form.cust_code.value,
      this.form.order_id.value,
      this.form.item_id.value,
      this.form.item_name.value,
      this.form.tax.value,
      this.form.amount.value,
      this.form.pay_type.value,
      this.form.auto_charge_type.value,
      this.form.service_type.value,
      this.form.div_settele.value,
      this.form.camp_type.value,
      this.form.terminal_type.value,
      this.form.success_url.value,
      this.form.cancel_url.value,
      this.form.error_url.value,
      this.form.pagecon_url.value,
      this.form.request_date.value,
      config.purchase.sbp.hashKey,
    ].join('')
  }

  private async getHash(): Promise<string> {
    const encoder = new TextEncoder()
    const hashSource = this.getHashSource()

    const encodedSource = encoder.encode(hashSource)
    const hashBuffer = await crypto.subtle.digest('SHA-1', encodedSource)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  }

  isValidMaxGroups(): boolean {
    if (this.maxGroups === null) {
      return false
    }
    if (this.orgGroupCount > this.maxGroups) {
      return false
    }
    return true
  }
}
